:root {
    --title-font-transform: scale(0.8, 1);
    

    --passive-dark-color: rgb(79, 23, 124);
  
    --light-medium-color: rgb(153, 52, 52);
    --medium-color: rgb(57, 13, 158); 
    /* --medium-color: rgb(95, 39, 39);  */
    --dark-color: rgb(40, 8, 117);
    /* --dark-color: rgb(68, 22, 22); */
    --darker-color: rgb(42, 18, 80);
  
    --dark-blue: rgb(22, 22, 98);
    --darker-blue: rgb(22, 22, 58);
    --dark-green: rgb(23, 80, 0);
  
    --gray-color: rgb(31, 31, 31);
    --blue-color: rgb(0, 86, 216);
    --purple-color: rgb(212, 0, 255);
    --light-blue-color: rgb(129, 179, 255);
  
    --button-color: rgb(117, 10, 10); 
  
    --simple-page-color: rgb(255, 255, 255);

    --orange-color: #d016ff;
    /* --orange-color: #ffa500; */
    --light-orange-color: #ffc559;

    --dark-gray-color: rgba(12, 15, 17, 1);
}
