:root {
    --h1-size: 60px;
    --h2-size: 30px;
    --h3-size: 20px;
    --p-size: 16px;
    --hero-h1-size: 75px;
    --home-text-size: 27px;
    
  
    --font-text: TextFont;
    --font-title: TitleFont;
    --font-home: HomeFont;
}
@media (max-width: 1366px) {
    :root {
      --hero-img-margin-left: 66.5%;
      --home-text-size: 23px;
    }
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --p-size: 4.25vw;
        --h1-size: 11.5vw;
        --h2-size: 7.5vw;
        --h3-size: 6vw;
        --hero-h1-size: max(7.5vw, 38px);
        --home-text-size: max(5vw, 17px);
    }
}

@font-face {
    font-family: TextFont;
    src: url('fonts/LinuxLibertine/LinLibertine_R.ttf');
}
@font-face {
    font-family: TitleFont;
    src: url('fonts/DayRoman/DAYROM__.ttf');
}
@font-face {
    font-family: StylizedFont;
    src: url('fonts/InknutAntiqua/InknutAntiqua-Light.ttf');
}
@font-face {
    font-family: HomeFont;
    src: url(fonts/RobotoCondensed/RobotoCondensed-Regular.ttf);
}
@font-face {
    font-family: HomeFontBold;
    src: url(fonts/RobotoCondensed/RobotoCondensed-Bold.ttf);
}
@font-face {
    font-family: LogoFont;
    src: url(fonts/Yrsa/Yrsa-Bold.ttf);
}