
:root {
    --image-size: 106px;
}

.ccc-image-holder {
    position: relative;
    width: var(--image-size);
    height: var(--image-size);
    border: solid black 2px;
    overflow: hidden;
}
.ccc-image-holder img {
    margin-left: 2px;
    margin-top: 2px;
    width: calc(var(--image-size) - 8px);
}
.ccc-image-holder .no-spell-spinner {
    width: calc(var(--image-size) * 2);
    height: calc(var(--image-size) * 2);
    left: calc(-0.5 * var(--image-size));
    top: calc(-0.5 * var(--image-size));
}






#Character-Builder input {
    font-size: var(--h3-size);
    padding: 6px;
    min-width: 225px;
    max-width: 30vw;
}

#Character-Builder label {
    font-size: var(--h3-size);
    font-family: HomeFont;
}







#Character-Builder .hero-button {
    min-width: 275px;
    max-width: 35vw;
}