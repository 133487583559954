:root {
    --page-max-width: 1100px;
    --page-min-width: 1010px;
    --page-border-width: 49px;
    --page-padding: 80px;

    --page-content-width: calc(0.79 * var(--page-max-width));
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --page-content-width: 90vw;
    }
}

.page {
    width: 100%;
    background-color: white;
    position: relative;
}

.page--has-background {
    background-image: url('./Images/paper-texture-page-desaturated.png');
    background-repeat: repeat;
    background-size: 90%;
}

.page-content {
    padding-top: 0px;
    width: var(--page-content-width);
    margin-left: calc(var(--content-margin-left) + var(--side-menu-width) + var(--side-menu-pad-right));
    padding-bottom: 50px;
}
.page-content-centered {
    margin-left: calc((100vw - var(--page-content-width)) / 2) !important;
}
@media (max-width: 1435px) {    
    .page-content {
        margin-left: calc(var(--side-menu-left) + var(--side-menu-width) + var(--side-menu-pad-right));
    }
}
@media (max-width: 1366px) {
    .page-content {
        margin: auto;   /* Side menu is hidden as well */
    }
}
@media (orientation: portrait) {
    .page-content {
        margin: auto;
    }
}
.page-subtitle {
    margin-top: 25px !important;
    width: 40%;
    margin: auto;
    text-align: center;

    font-style: italic;
    font-size: 1.1em;
    letter-spacing: 0em;
    color: var(--medium-color);
}