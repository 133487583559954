
.monster-preview {
    position: absolute;
    border: solid var(--dark-color) 2px;
    background-color: white;
    padding: var(--element-padding);
    max-width: 600px;
}



.monster-variants {
    position: absolute;
    width: 80%;
    text-align: center;
    top: -75px;

    color: gray;
    font-style: italic;
}
.monster-story-ideas {
    background-color: white;
    border-radius: 4px;
    border-bottom-right-radius: 30px;

    padding: var(--element-padding-half);
}

.monster .separator {
    margin-top: var(--element-padding-half);    /* Duplicate because this might be inside other things */
    margin-bottom: var(--element-padding-half); /* Duplicate because this might be inside other things */
}